$primary-bg: #121a2a;
$secondary-bg: #171b2a;
$yellow-bg: #ffd700;
$white: #ffffff;
$font-family: "Poppins", sans-serif;

.chatPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  padding-top: 40px;
  // background-color: $secondary-bg;
  max-width: 880px;
  font-family: $font-family;
  margin: 0 auto;
  // margin-top: 80px;
  padding: 50px 165px;
  @media (max-width: 600px) {
    padding: 60px 20px 0 20px;
  }

  .bgLeft {
    position: absolute;
    top: 100vh;
    left: -26vw;
    z-index: -1;
    @media (max-width: 600px) {
      top: 100vh;
      left: -26vw;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.header {
  position: relative;
  text-align: center;

  @media (max-width: 600px) {
    margin-bottom: 12px;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-bottom: 22px;
    @media (max-width: 600px) {
      gap: 16px;
      margin-bottom: 28px;
    }

    .icon {
      width: 100px;
      height: 72px;

      @media (max-width: 600px) {
        width: 50px;
        height: 36px;
      }
    }

    h2 {
      font-size: 48px;
      font-weight: bold;
      margin: 0;
      @media (max-width: 600px) {
        font-size: 18px;
      }
    }
  }

  // .presets {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: center;
  //   gap: 64px;
  //   margin-bottom: 20px;
  //   @media (max-width: 600px) {
  //     gap: 16px;
  //   }

  //   .presetButton {
  //     background-color: $secondary-bg;
  //     color: $white;
  //     border-radius: 10px;
  //     padding: 10px 15px;
  //     cursor: pointer;
  //     border: 1px solid #728284;
  //     background: rgba(217, 217, 217, 0);

  //     &:hover {
  //       background-color: lighten($secondary-bg, 10%);
  //     }

  //     &.clickedPreset {
  //       background-color: $yellow-bg;
  //       color: $primary-bg;
  //     }

  //     @media (max-width: 600px) {
  //       padding: 8px 10px;
  //       font-size: 12px;
  //     }
  //   }
  // }
  .presets {
    width: 100%;
    margin-bottom: 20px;

    .presetButton {
      float: left;
      margin: 10px; // 设置按钮间隙
      background-color: $secondary-bg;
      color: $white;
      border-radius: 10px;
      padding: 10px 15px;
      cursor: pointer;
      border: 1px solid #728284;
      background: rgba(217, 217, 217, 0);
      text-align: center;
      box-sizing: border-box;

      &:hover {
        background-color: lighten($secondary-bg, 10%);
      }

      &.clickedPreset {
        background-color: $yellow-bg;
        color: $primary-bg;
      }

      @media (max-width: 600px) {
        margin: 8px;
        padding: 8px 10px;
        font-size: 12px;
      }
    }
  }

  .clearfix::after {
    content: "";
    display: block;
    clear: both;
  }
}

.chatContainer {
  width: 100%;
  max-width: 800px;
  // background-color: $secondary-bg;
  border-radius: 10px;
  padding: 16px 32px;

  @media (max-width: 600px) {
    max-width: 100%;
    padding: 10px 10px 24px;
  }
  .bgRight {
    position: absolute;
    bottom: 30vh;
    right: -25vh;
    height: 1399px;
    z-index: -1;
    @media (max-width: 600px) {
      bottom: 125vh;
      right: 0vh;
      height: 1000px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.defaultMessage {
  text-align: center;
  font-size: 20px;
  color: #bec7d4;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  @media (max-width: 600px) {
    font-size: 16px;
    height: 100px;
    p {
      font-size: 12px;
    }
  }
}

.messageList {
  border-radius: 10px;
  height: 400px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 10px;

  .message {
    padding: 0 10px;
    border-radius: 10px;
    max-width: 70%;
    word-wrap: break-word;

    @media (max-width: 600px) {
      font-size: 14px;
    }

    &.sent {
      align-self: flex-end;
      background-color: #f3e830;
      color: #000;
      text-align: left;
    }

    &.received {
      align-self: flex-start;
      background-color: lighten($secondary-bg, 15%);
      text-align: left;
    }
  }

  &:before {
    content: "";
    flex-grow: 1;
  }

  @media (max-width: 600px) {
    height: 300px;
    padding: 0px;
  }
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  .input {
    flex-grow: 1;
    padding: 20px;
    border-radius: 16px;
    border: none;
    font-size: 20px;

    @media (max-width: 600px) {
      font-size: 12px;
      padding: 12px;
    }
  }

  .sendButton {
    background-color: lighten($secondary-bg, 10%);
    border: none;
    color: $white;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
    @media (max-width: 600px) {
      font-size: 16px;
    }

    &:hover {
      background-color: lighten($secondary-bg, 15%);
    }

    @media (max-width: 600px) {
      padding: 8px 10px;
    }
  }
}

.messageRow {
  display: flex;
  align-items: center;
  gap: 10px;

  &.sentRow {
    justify-content: flex-end;
  }

  &.receivedRow {
    justify-content: flex-start;
  }
}

.avatar {
  width: 40px;
  height: 40px;

  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
}

.loading {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $white;
  font-size: 18px;
  font-weight: bold;

  span {
    animation: blink 1s infinite;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

@keyframes blink {
  0%,
  100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
}
@media (min-width: 768px) and (max-width: 1198px) {
  .bgRight {
    display: unset;
    height: 0px !important;
    width: 0px;
  }
}
